import axios from 'axios' // 引入axios
import store from '../store/actions'
import router from '../router/index'
import { Message } from 'element-ui' // 引入element-ui Message组件

export function request(config) {
  // 创建axios实例
  const instance = axios.create({
    // baseURL: 'http://www.ssjue.top',
    // baseURL: 'http://zhitu.maer.pro',
    // baseURL: 'http://m..com', // pc
    // baseURL: 'http://fagelipin.51msoft.com',
    baseURL: 'https://m.fagelipin.com', // pc
    timeout: 20000
  })
  // 请求拦截器
  instance.interceptors.request.use(
    config => {
      return config
    },
    err => {
      return err
    }
  )
  // 响应拦截器
  instance.interceptors.response.use(
    res => {
      return res.data
    },
    err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            // 401 清除token信息并跳转到登录页面
            // store.commit('logout')
            window.localStorage.removeItem('userInfo')
            router.replace({
              path: '/login'
            })
            Message.error('请重新登录')
            break
          default:
            Message.error('网络错误，请稍后重试')
            break
        }
      }
      return err
    }
  )
  // 发送真正的网络请求
  return instance(config)
}
