<template>
  <div class="side-menu">
    <div class="buynow-btn" @click="orderNow">立即下单</div>
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" router @select="handleSelect">
      <el-submenu :index="item.index" v-for="(item, index) in menu" :key="index">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{item.title}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="(i, k) in item.items" :key="k" :index="i.path">{{i.name}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menu: [
        {
          index: '/orderAdmin',
          title: '订单管理',
          icon: 'el-icon-set-up',
          items: [
            {
              name: '礼品采购',
              path: '/orderAdmin/purchase'
            },
            {
              name: '订单列表',
              path: '/orderAdmin/orderList'
            },
            {
              name: '店铺绑定',
              path: '/orderAdmin/storeBind'
            },
            {
              name: '发件地址',
              path: '/orderAdmin/sendAddress'
            }
          ]
        },
        {
          index: '/account',
          title: '个人中心',
          icon: 'el-icon-user',
          items: [
            {
              name: '基础信息',
              path: '/account/base'
            },
            {
              name: '余额管理',
              path: '/account/balance'
            },
            {
              name: '邀请奖励',
              path: '/account/inviteRewards'
            },
            {
              name: '邀请好友',
              path: '/account/InviteFriends'
            }
          ]
        },
        {
          index: '/workOrder',
          title: '工单中心',
          icon: 'el-icon-document',
          items: [
            {
              name: '提交工单',
              path: '/workOrder/orderSumbit'
            },
            {
              name: '我的工单',
              path: '/workOrder/myOrder'
            }
          ]
        },
        {
          index: '/oem',
          title: '网站后台',
          icon: 'el-icon-monitor',
          items: [
            {
              name: '网站管理',
              path: '/oem/manage'
            },
            {
              name: '收益统计',
              path: '/oem/Income'
            }
          ]
        }
      ]
    }
  },
  methods: {
    // 立即下单
    orderNow () {
      this.$router.push('/orderAdmin/purchase')
    },
    // 菜单激活回调
    handleSelect (indexPath) {
      this.$emit('getIndexPath', indexPath)
    }
  }
}

</script>

<style scoped src="styles/components/sideMenu.css">
</style>

<style>
  .side-menu .el-menu-vertical-demo.el-menu {
    position: fixed;
    top: 128px;
    bottom: 0;
    left: 0;
    width: 200px;
    overflow-y: auto;
  }

  .side-menu .el-menu-vertical-demo.el-menu::-webkit-scrollbar {
    display: none;
  }

  .side-menu .el-menu {
    border-right: 0px;
  }

  .side-menu .el-menu .el-menu-item {
    font-weight: 600;
    color: rgba(0,0,0,.65);
  }

  .side-menu .el-submenu__title i:last-child {
    font-weight: 600;
  }

  .side-menu .el-submenu__title, .side-menu .el-submenu__title i {
    color: #000000A6;
  }

  .side-menu .el-submenu.is-opened .el-submenu__title, .side-menu .el-submenu.is-opened .el-submenu__title i {
    color: #1890ff;
  }

  .side-menu .el-menu .el-menu-item.is-active {
    color: #1890ff;
    border-right: 3px #1890ff solid;
  }
</style>
