<template>
  <div id="app">
    <router-view
      v-wechat-title="
        $route.meta.title + ' - ' + $store.getters.getSiteInfo.name
      "
    />
  </div>
</template>

<script>
import { getSiteInfo } from 'network/api'

export default {
  created () {
    // 获取配置信息 并存入vuex
    getSiteInfo({ appkey: this.$appkey }).then((res) => {
      if (res.code === 1) {
        this.$store.dispatch('getSiteInfo', res.data)
      }
    })
  }
}
</script>

<style></style>
