import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css'
import 'styles/base/base.css'

Vue.use(VueWechatTitle)
Vue.config.productionTip = false
// Vue.prototype.$url = 'http://zhitu.maer.pro'
// Vue.prototype.$url = 'http://www.ssjue.top'
Vue.prototype.$url = 'https://www.fagelipin.com'
// Vue.prototype.$url = 'http://dev.www..com'
// Vue.prototype.$api_url = 'http://fagelipin.51msoft.com'
// 配置appkey
Vue.prototype.$appkey = 'c81e728d9d4c2f636f067f89cc14862c'
Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
