export default {
  getUserInfo: state => {
    state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    return state.userInfo
  },
  getSiteInfo: state => {
    state.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    return state.siteInfo
  }
}
