import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseStructure from 'components/baseStructure/BaseStructure'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    component: resolve => require(['views/index/Index'], resolve),
    meta: {
      title: '首页'
    }
  },
  // 分站开通
  {
    path: '/join',
    component: resolve => require(['views/join/Join'], resolve),
    meta: {
      title: '分站开通'
    }
  },
  // 系统公告
  {
    path: '/notice',
    component: resolve => require(['views/notice/Notice'], resolve),
    meta: {
      title: '系统公告'
    }
  },
  // 登录
  {
    path: '/login',
    component: resolve => require(['views/login/Login'], resolve),
    meta: {
      title: '登录'
    }
  },
  // 注册
  {
    path: '/register',
    component: resolve => require(['views/register/Register'], resolve),
    meta: {
      title: '注册'
    }
  },
  // 重置密码
  {
    path: '/rePwd',
    component: resolve => require(['views/rePwd/RePwd'], resolve),
    meta: {
      title: '重置密码'
    }
  },
  {
    path: '/zfblank',
    component: resolve => require(['views/index/zfblank'], resolve),
    meta: {
      title: '支付页面'
    }
  },
  // 订单管理
  {
    path: '/orderAdmin',
    redirect: '/orderAdmin/purchase',
    component: BaseStructure,
    beforeEnter (to, from, next) {
      if (window.localStorage.getItem('userInfo')) {
        next()
      } else {
        next('login')
      }
    },
    children: [
      {
        path: 'purchase',
        component: resolve => require(['views/orderAdmin/purchase/Purchase'], resolve),
        meta: {
          title: '礼品采购'
        }
      },
      {
        path: 'orderList',
        component: resolve => require(['views/orderAdmin/orderList/OrderList'], resolve),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: 'storeBind',
        component: resolve => require(['views/orderAdmin/storeBind/StoreBind'], resolve),
        meta: {
          title: '店铺绑定'
        }
      },
      {
        path: 'sendAddress',
        component: resolve => require(['views/orderAdmin/sendAddress/SendAddress'], resolve),
        meta: {
          title: '发件地址'
        }
      }
    ]
  },

  // 个人中心
  {
    path: '/account',
    redirect: '/account/base',
    component: BaseStructure,
    children: [
      {
        path: 'base',
        component: resolve => require(['views/account/base/Base'], resolve),
        meta: {
          title: '基础信息'
        }
      },
      {
        path: 'balance',
        component: resolve => require(['views/account/balance/Balance'], resolve),
        meta: {
          title: '余额管理'
        }
      },
      {
        path: 'inviteRewards',
        component: resolve => require(['views/account/inviteRewards/InviteRewards'], resolve),
        meta: {
          title: '邀请奖励'
        }
      },
      {
        path: 'inviteFriends',
        component: resolve => require(['views/account/inviteFriends/InviteFriends'], resolve),
        meta: {
          title: '邀请好友'
        }
      }
    ]
  },

  // 工单中心
  {
    path: '/workOrder',
    redirect: '/workOrder/orderSumbit',
    component: BaseStructure,
    children: [
      {
        path: 'orderSumbit',
        component: resolve => require(['views/workOrder/orderSumbit/OrderSumbit'], resolve),
        meta: {
          title: '提交工单'
        }
      },
      {
        path: 'myOrder',
        component: resolve => require(['views/workOrder/myOrder/MyOrder'], resolve),
        meta: {
          title: '我的工单'
        }
      }
    ]
  },

  // 网站后台
  {
    path: '/oem',
    redirect: '/oem/manage',
    component: BaseStructure,
    children: [
      {
        path: 'manage',
        component: resolve => require(['views/oem/manage/Manage'], resolve),
        meta: {
          title: '网站管理'
        }
      },
      {
        path: 'income',
        component: resolve => require(['views/oem/manage/Income'], resolve),
        meta: {
          title: '收益统计'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
